@import "../assets/ginto-webfont/stylesheet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
    @apply text-3xl;
    @apply font-bold;
    @apply text-center;
}

h2 {
    @apply text-xl;
}