.nav {
  color: #3f4b5b;
  @apply block;
  padding: 1rem 1rem;
  @apply rounded-md;
  @apply font-medium;
  @apply text-xl;
  @apply flex;
  @apply items-center;
  &.active {
    color: #2089b3;
    background-color: #d2e7f0;
  }
}