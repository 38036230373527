@font-face {
    font-family: 'Ginto';
    src: url('Ginto-MediumItalic.eot');
    src: url('Ginto-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Ginto-MediumItalic.woff2') format('woff2'),
    url('Ginto-MediumItalic.woff') format('woff'),
    url('Ginto-MediumItalic.ttf') format('truetype'),
    url('Ginto-MediumItalic.svg#Ginto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ginto';
    src: url('Ginto-Bold.eot');
    src: url('Ginto-Bold.eot?#iefix') format('embedded-opentype'),
    url('Ginto-Bold.woff2') format('woff2'),
    url('Ginto-Bold.woff') format('woff'),
    url('Ginto-Bold.ttf') format('truetype'),
    url('Ginto-Bold.svg#Ginto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ginto';
    src: url('Ginto-BoldItalic.eot');
    src: url('Ginto-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Ginto-BoldItalic.woff2') format('woff2'),
    url('Ginto-BoldItalic.woff') format('woff'),
    url('Ginto-BoldItalic.ttf') format('truetype'),
    url('Ginto-BoldItalic.svg#Ginto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ginto';
    src: url('Ginto-Medium.eot');
    src: url('Ginto-Medium.eot?#iefix') format('embedded-opentype'),
    url('Ginto-Medium.woff2') format('woff2'),
    url('Ginto-Medium.woff') format('woff'),
    url('Ginto-Medium.ttf') format('truetype'),
    url('Ginto-Medium.svg#Ginto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ginto';
    src: url('Ginto-RegularItalic.eot');
    src: url('Ginto-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('Ginto-RegularItalic.woff2') format('woff2'),
    url('Ginto-RegularItalic.woff') format('woff'),
    url('Ginto-RegularItalic.ttf') format('truetype'),
    url('Ginto-RegularItalic.svg#Ginto-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ginto';
    src: url('Ginto-Regular.eot');
    src: url('Ginto-Regular.eot?#iefix') format('embedded-opentype'),
    url('Ginto-Regular.woff2') format('woff2'),
    url('Ginto-Regular.woff') format('woff'),
    url('Ginto-Regular.ttf') format('truetype'),
    url('Ginto-Regular.svg#Ginto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

